<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    title="Tracking"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <filter-slot
      :no-visible-principal-filter="true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="[]"
      :filter-principal="{}"
      @reload="$refs['refTrackingTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTrackingTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchCedSettingsCommission"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(new_value)="data">
            {{ data.item.type_comission_number === 1? '$' : ' ' }}
            {{ data.item.new_value }}
            {{ data.item.type_comission_number === 2? '%' : ' ' }}          </template>
          <template #cell(old_value)="data">
            {{ data.item.type_comission_number === 1? '$' : ' ' }}
            {{ data.item.old_value }}
            {{ data.item.type_comission_number === 2? '%' : ' ' }}          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(evaluated_by)="data">
            {{ data.item.evaluated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
          </template>
          <template #cell(status_description)="data">
            <b-badge
              :variant="getVariantColor(data.item.status)"
              class="text-center w-100"
            >
              {{ data.item.status_description }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status === 1"
              size="sm"
              variant="outline-danger"
              @click="cancelChange(data.item.id, data.item)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Cancel</span>
            </b-button>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'

export default {
  props: {
    idCommission: {
      type: Number,
      default: 0,
    },
    typeCommission: {
      type: Number,
      default: 0,
    },
    typeTable: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: 'type_description',
          sortable: false,
          label: 'Type',
        },
        {
          key: 'category_description',
          sortable: false,
          label: 'Category',
        },
        {
          key: 'old_value',
          sortable: false,
          label: 'Old Value',
        },
        {
          key: 'new_value',
          sortable: false,
          label: 'New Value',
        },
        {
          key: 'created_by',
          sortable: true,
          label: 'Requested by',
        },
        {
          key: 'evaluated_by',
          sortable: false,
          label: 'Processed by',
        },
        {
          key: 'status_description',
          sortable: false,
          label: 'Status',
          thStyle: {
            textAlign: 'center',
          },
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
        },
      ],
      trackingItems: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.onControl = true
    this.addPreloader()
    this.removePreloader()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async cancelChange(id, item) {
      const response = await CeSetCommissionsService.updateStatusCommission({
        tracking_id: id,
        status: 4,
        user_id: this.currentUser.user_id,
        type_table: item.type_table,
      })
      if (response.status === 200) {
        item.status = 4
        item.status_description = 'CANCELLED'
        this.$emit('canceledChange')
        this.$refs.refTrackingTable.refresh()
      }
    },
    async searchCedSettingsCommission(ctx) {
      const response = await CeSetCommissionsService.searchCedSettingsCommission({
        type_id: this.idCommission,
        orderby: 'created_at',
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        perPage: ctx.perPage,
      })
      this.trackingItems = response.data.data
      this.startPage = response.data.from
      this.paginate.currentPage = response.data.current_page
      this.paginate.perPage = response.data.per_page
      this.totalRows = response.data.total
      this.toPage = response.data.to
      return this.trackingItems || []
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-success'
        case 3:
          return 'light-danger'
        default:
          return 'light-info'
      }
    },
  },
}
</script>

<style scoped>

</style>
